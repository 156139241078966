import { AtSignIcon, EmailIcon } from "@chakra-ui/icons"
import {
  Box,
  Flex,
  Link,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => {
  const techStackColor = useColorModeValue("gray.600", "gray.300")
  return (
    <Layout>
      <SEO title="Char's Home Page" />
      <Link
        display="inline-flex"
        alignItems="center"
        fontFamily="mono"
        fontSize="xl"
        isExternal
        _focus={{ outline: "none" }}
        href="https://github.com/charact3"
      >
        <AtSignIcon mr={2} />
        charact3
      </Link>
      <Text fontSize="lg" my={4}>
        Software Engineer
      </Text>
      <Box fontFamily="mono" fontSize="sm">
        <VStack spacing={2} align="stretch" color={techStackColor}>
          <Text>Backend: Python, Go</Text>
          <Text>Frontend: React</Text>
          <Text>DevOps: Ansible, Docker, K8s</Text>
        </VStack>
        <Flex mt={4} alignItems="center">
          <EmailIcon mr={2} /> charact3@gmail.com
        </Flex>
      </Box>
    </Layout>
  )
}

export default IndexPage
